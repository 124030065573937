<template>
    <div>
        <label v-if="props.label" class="radio-select__groupLabel">{{
            label
        }}</label>
        <div class="radio-select" :class="{ 'radio-select--grid': props.grid }">
            <div
                v-for="choice in choices"
                :key="choice.id"
                class="radio-select__item"
                :class="{
                    'radio-select__item--disabled': choice.disabled,
                    active: isActive(choice),
                }"
                :data-cy="choice.dataCy"
            >
                <input
                    type="radio"
                    :id="idPrefix + choice.id"
                    :value="getValue(choice)"
                    :disabled="choice.disabled"
                    v-model="internalSelect"
                    :name="idPrefix + name"
                    @click="updateVal(choice)"
                />
                <label :for="idPrefix + choice.id">
                    <slot name="default" v-bind="choice">
                        <FormRadioSelectItem :text="choice.text" />
                    </slot>
                </label>
            </div>
            <slot name="add">
                <div v-if="error" class="equal-small-3 radio__error">
                    {{ error }}
                </div>
            </slot>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    modelValue: {
        type: [String, Number, null],
        default: null,
    },
    choices: {
        type: Array,
        default: () => [],
    },
    preselect: {
        type: Boolean,
        default: false,
    },
    grid: {
        type: Boolean,
        default: false,
    },
    name: {
        type: String,
        default: '',
        required: false,
    },
    error: {
        type: String,
        default: null,
    },
    label: {
        type: String,
        default: null,
    },
});

const choices = toRef(props, 'choices');
const route = useRoute();
const router = useRouter();

const idPrefix = useId();

const internalSelect = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', value);
        updateQueryParams();
    },
});

const isActive = computed(() => (choice) => {
    if (choice.disabled === true) {
        return false;
    }

    if ('value' in choice) {
        return choice.value === internalSelect.value;
    }

    return String(choice.id) === String(internalSelect.value);
});

function getValue(choice) {
    return choice.value ? choice.value : choice.id;
}

function updateVal(choice) {
    internalSelect.value = getValue(choice);
}

const emit = defineEmits(['update:modelValue']);

function updateQueryParams() {
    if (props.name && internalSelect.value) {
        const query = {};
        query[props.name] = internalSelect.value;
        router.replace({ query });
    }
}
watch(choices, () => {
    preselect();
});
onBeforeMount(() => {
    preselect();
});

function preselect() {
    try {
        if (props.preselect) {
            if (route.query && props.name && route.query[props.name]) {
                internalSelect.value = parseInt(route.query[props.name]);
            } else {
                const item = choices.value.find((choice) => !choice.disabled);
                if (item) internalSelect.value = getValue(item);
            }
        }
    } catch (e) {
        console.log(e);
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

@include media-breakpoint-down(640) {
    .radio-select__groupLabel {
        width: 100%;
    }
}
.radio-select__groupLabel {
    margin-bottom: 5px;
}

.radio-select {
    display: flex;
    flex-wrap: wrap;
    padding: 2px;
    gap: 20px 0;

    @include media-breakpoint-down(640) {
        flex-wrap: wrap;
        flex-direction: row;
        display: inline-flex;
        gap: 0;
    }

    &--grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 40px;
        row-gap: 40px;
        margin: 0;

        @include media-breakpoint-down(640) {
            grid-template-columns: 1fr;
            row-gap: 20px;
            flex-direction: column;
        }

        .radio-select__item {
            margin: 0 !important;

            label {
                width: 100%;
                height: 100%;
            }
        }
    }

    &__item {
        color: $color-light;
        background-color: unset;
        outline: 1px solid $color-plain-grey-30;
        border-radius: 10px;
        transition: 0.3s ease;
        margin-right: 20px;
        &:hover,
        &:focus,
        &:active {
            outline-color: $color-plain-grey-50;
        }
        @include media-breakpoint-down(640) {
            margin-right: 15px;
            margin-bottom: 20px;
        }

        &.active {
            outline: 2px solid #cccccc;
        }

        input {
            display: none;
        }

        label {
            cursor: pointer;
            display: flex;
            border-radius: 10px;
            height: 100%;
        }

        &--disabled {
            color: $color-disabled;
            opacity: 0.5;
            pointer-events: none;

            label {
                cursor: not-allowed !important;
                pointer-events: none;
            }
        }
    }
}
.radio__error {
    color: #ee2323;
    margin-top: 10px;
}
</style>
